<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i><span class="link" @click="$router.push('/client/server')">服务列表</span> \ 扫描客户端服务
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <!--            <div class="handle-box">-->
            <!--                <el-button style="margin-right: 5px;float: right" v-if="hasAuth('SaveClientServer')"-->
            <!--                           icon="el-icon-search"-->
            <!--                           class="handle-del"-->
            <!--                           @click="scanVisible=true"-->
            <!--                >扫描客户端服务-->
            <!--                </el-button>-->
            <!--            </div>-->
            <el-form ref="form" :model="form" label-width="100px" aria-autocomplete="none">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="客户端名称">
                            <span>{{client.name}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="客户端IP">
                            <span>{{client.ip}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="客户端MAC">
                            <span>{{client.mac}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="心跳状态">
                            <span v-if="client.healthState===1" class="success">正常</span>
                            <span v-else class="error">离线</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table
                            :data="tableData"
                            border
                            class="table"
                            :height="220"
                            header-cell-class-name="table-header"
                    >
                        <el-table-column label="客户端已有服务" align="left">
                            <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                            <el-table-column prop="name" label="名称" align="center"></el-table-column>
                            <el-table-column prop="serverId" label="服务" align="center">
                                <template #default="scope">
                                    <span>{{common.arrayFindReturn(servers,scope.row.serverId)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="ports" label="服务端口号" align="center">
                                <template #default="scope">
                                    <span v-if="!scope.row.ports || scope.row.ports.length<1">无端口</span>
                                    <div v-else>
                                        <span v-for="(item,index) in scope.row.ports" :key="index"><span
                                                v-if="index>0">,</span>
                                     <span :class="{success:scope.row.runPorts&&scope.row.runPorts.indexOf(item)>=0}">{{item}}</span>
                                    </span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="count" label="运行实例数" align="center">
                                <template #default="scope">
                                    <span title="运行中/实例数">{{scope.row.runCount}}/{{scope.row.count}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="状态" align="center">
                                <template #default="scope">
                                    <span v-if="scope.row.state===1" class="success">启用</span>
                                    <span v-if="scope.row.state===0" class="error">禁用</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="心跳状态" align="center">
                                <template #default="scope">
                                    <span v-if="scope.row.healthState===1" class="success">正常</span>
                                    <span v-else-if="scope.row.type==='web'">无状态</span>
                                    <span v-else class="error">离线</span>
                                    <br/>
                                    <span v-if="scope.row.refreshDate && scope.row.refreshDate>0">{{scope.row.refreshDate.toDate('yyyy-MM-dd HH:mm:ss')}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="closePorts" label="关闭端口号" align="center">
                                <template #default="scope">
                                    <span v-for="(item,index) in scope.row.closePorts" :key="index"><span
                                            v-if="index>0">,</span>{{item}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="path" label="路径" align="center"></el-table-column>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row>
                        <div style="font-weight:bold;background-color: #f5f7fa;padding: 10px 0;color: #909399;font-size: 14px;width: 100%;padding-left: 10px;height: 20px;margin-top: 5px;border: 1px solid #EEE">
                            <span >扫描结果</span>
                            <div class="scan-btn" style="width: 800px">
                                <el-input class="form-item full" v-model="query.path"
                                          placeholder="输入扫描路径,会扫描该路径下所有tomcat或jar包信息" :clearable="true">
                                    <template #prepend>
                                            <el-select style="width: 230px" v-model="query.types" multiple placeholder="扫描类型">
                                                <el-option key="jar" label="jar" value="jar"></el-option>
                                                <el-option key="war" label="war" value="war"></el-option>
                                                <el-option key="web" label="web" value="web"></el-option>
                                            </el-select>
                                            <div style="width: 2px;height: 100%;background: #FFF;display: inline-block"></div>
                                            <el-select style="width: 120px" v-model="query.defaultPath" placeholder="默认路径" @change="(val)=>{query.path = val}">
                                                <el-option
                                                        v-for="item in bases.defaultPath"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.other"
                                                >
                                                </el-option>
                                            </el-select>
                                    </template>
                                    <template #append>
                                        <el-button  v-if="hasAuth('SaveClientServer')"
                                                   type="primary"
                                                   icon="el-icon-search"
                                                   class="handle-del"
                                                    @click="startScan()"
                                        >开始扫描
                                        </el-button>
                                    </template>
                                </el-input>
                            </div>

                        </div>
                </el-row>
                <el-row>
                    <el-col :span="16">
                        <el-table
                                :data="scanData"
                                border
                                class="table"
                                :height="common.calcTableHeight(540)"
                                header-cell-class-name="table-header"
                        >
                            <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                            <el-table-column prop="port" label="端口号" align="center" width="100"></el-table-column>
                            <el-table-column prop="type" label="部署类型" align="center" width="100"></el-table-column>
                            <el-table-column prop="closePort" label="关闭端口号" align="center" width="100"></el-table-column>
                            <el-table-column prop="path" label="路径" align="center"></el-table-column>
                            <el-table-column fixed="right" label="操作" class-name="table-op" width="160"
                                             align="center"
                                             v-if="hasAuth('UpdateClientServer','DeleteClientServer')">
                                <template #default="scope">
                                    <el-button v-if="hasAuth('UpdateClientServer')"
                                                      type="primary"
                                                      icon="el-icon-edit"
                                                      @click="scope.row.edit=true,handleEdit(scope.$index, scope.row)">
                                        添加至服务
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="8">
                        <div style="text-align:center;background-color: #f5f7fa;padding: 14px 0;color: #909399;font-size: 14px;font-weight: bold;border: 1px solid #EEE">扫描日志</div>
                        <ul ref="logs" class="scan-log" :style="{height:common.calcTableHeight(590)+'px'}">
                            <li v-for="(log,index) in logs" class="infinite-list-item" :key="index" :class="{error:log.level==='error'}">{{log.msg}}</li>
                        </ul>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- 编辑弹出框 -->
        <el-dialog title="添加客户端服务" v-model="editVisible" width="1000px" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="120px" aria-autocomplete="none">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称" required>
                            <span v-if="!form.edit">{{form.name}}</span>
                            <el-input v-else class="form-item" v-model="form.name" placeholder="名称"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="服务" required>
                            <span v-if="!form.edit">{{common.arrayFindReturn(servers,form.serverId)}}</span>
                            <el-select v-else v-model="form.serverId"  class="form-item" placeholder="服务">
                                <el-option :key="item.id" :label="item.name" :value="item.id"
                                           v-for="item in servers"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态" required>
                            <span v-if="!form.edit">{{form.state===1?'启用':'禁用'}}</span>
                            <el-select v-else class="form-item" v-model="form.state">
                                <el-option key="1" label="启用" :value="1"></el-option>
                                <el-option key="-1" label="禁用" :value="-1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部署方式" required>
                            <span v-if="!form.edit">{{form.type}}</span>
                            <el-select v-else class="form-item" v-model="form.type">
                                <el-option key="jar" label="jar" value="jar"></el-option>
                                <el-option key="war" label="war" value="war"></el-option>
                                <el-option key="web" label="web" value="web"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运行实例数">
                            <span v-if="!form.edit">{{form.runCount}}/{{form.count}}</span>
                            <el-input-number :min="1" :max="20" v-else :precision="0" class="form-item" v-model="form.count" placeholder="运行实例数"
                                             :clearable="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type!=='web'">
                    <el-col :span="12">
                        <el-form-item label="服务端口号">
                            <span v-if="!form.edit"> <span v-if="form.ports"><span v-for="(item,index) in form.ports" :key="index"><span v-if="index>0">,</span>{{item}}</span></span></span>
                            <el-input v-else class="form-item" v-model="form.ports" placeholder="服务端口号" @change="checkPort('ports')"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="推荐端口号">
                            <span> <span v-if="form.switchPorts"><span class="point" @click="addPort('ports',item)" v-for="(item,index) in filterPort(form.switchPorts)" :key="index"><span v-if="index>0">,</span>{{item}}</span></span></span>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row v-if="form.type==='war'">
                    <el-col :span="12">
                        <el-form-item label="关闭端口号">
                            <span v-if="!form.edit"> <span v-if="form.closePorts"><span v-for="(item,index) in form.closePorts" :key="index"><span v-if="index>0">,</span>{{item}}</span></span></span>
                            <el-input v-else class="form-item" v-model="form.closePorts" placeholder="关闭端口号" @change="checkPort('closePorts')"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="推荐关闭端口号">
                            <span> <span v-if="form.switchClosePorts"><span class="point" @click="addPort('closePorts',item)" v-for="(item,index) in filterPort(form.switchClosePorts)" :key="index"><span v-if="index>0">,</span>{{item}}</span></span></span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type==='jar'||form.type==='war'">
                    <el-col :span="12">
                        <el-form-item label="自动修复" required>
                            <span v-if="!form.edit">{{form.repairState===1?'启用':'禁用'}}</span>
                            <el-select v-else class="form-item" v-model="form.repairState">
                                <el-option key="1" label="启用" :value="1"></el-option>
                                <el-option key="0" label="禁用" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type==='war'">
                    <el-col :span="24">
                        <el-form-item label="容器路径" required>
                            <span v-if="!form.edit">{{form.containerPath}}</span>
                            <el-input v-else class="form-item full" v-model="form.containerPath" placeholder="容器所在路径"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type==='jar'">
                    <el-col :span="24">
                        <el-form-item label="虚拟机路径" required>
                            <span v-if="!form.edit">{{form.containerPath}}</span>
                            <el-input v-else class="form-item full" v-model="form.containerPath" placeholder="虚拟机所在路径,为空时使用客户端相同虚拟机,例:D:\Program Files\Java\jre1.8.0_261"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目路径" required>
                            <span v-if="!form.edit">{{form.path}}</span>
                            <el-input v-else class="form-item full" v-model="form.path" placeholder="项目所在路径"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import store from "../../store";
    import api from "../../utils/api";

    export default {
        name: "ClientServerScan",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                pageTotal: 0,
                form: {},
                query: {},
                loading: false,
                editVisible: false,
                servers: [],
                clients: [],
                client: {id: null},
                scanData: [],
                bases:{defaultPath:[]},
                logs:[],
                //已存在端口号
                ports:[],
                //扫描标记
                scan:false,
            };
        },
        created() {
            this.init();
            this.getServer();
            api.getBase("default_path",(data)=>{
                this.bases.defaultPath = data;
            })
        },
        methods: {
            //初始化
            init(){
                this.client.id = this.$route.query.clientId;
                if (this.client.id) {
                    this.searchClient({id: this.client.id}, (data) => {
                        if (data) {
                            this.client = data;
                        }
                    });
                }
                this.getData();


            },
            getData() {
                http.post("/client/server/list", {clientId:this.client.id,page:1,rows:100}).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.pageTotal = data.total;
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取客户端所有端口号
            getClientPorts() {
                http.post("/client/server/list/port", {clientId:this.client.id}).then(data => {
                    data = data.data;
                    if (data) {
                        this.ports = data;
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取服务
            getServer() {
                http.post("/server/list").then(data => {
                    data = data.data;
                    if (data) {
                        this.servers = data;
                    }
                })
            },
            //获取客户端
            searchClient(param, callback) {
                if (!(param instanceof Object)) {
                    param = {key: param};
                }
                http.post("/client/list", param, {animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        if (callback) {
                            callback(data);
                            this.clients.push(data)
                            return;
                        }
                        this.clients = data.rows;
                    }
                })
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },
            // 编辑操作
            handleEdit(index, row) {
                this.getClientPorts();
                this.form = Object.assign({}, row);
                this.form.ports=row.port+"";
                if(row.closePort){
                    this.form.closePorts=row.closePort+"";
                }
                this.form.count=1;
                this.form.state=1;
                this.editVisible = true;
            },
            // 保存编辑
            saveEdit() {
                if (this.form.ports) {
                    this.form.ports = this.form.ports.replace(/，/g, ",");
                }
                this.form.clientId = this.client.id;
                http.post(this.form.id ? '/client/server/update' : '/client/server/save', this.form, {alertError: true}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.getData();
                        this.$message.success(`操作成功`);
                        this.editVisible = false;
                        return;
                    }
                    this.$message.error(data.msg);
                }).catch(e => {
                    this.$message.error(e.message);
                })

            },
            //开始扫描
            startScan(){
                if(!this.client.id||!this.query.path){
                    return;
                }
                if(this.client.healthState!==1){
                    this.$message.error("客户端已离线,无法进行扫描操作");
                    return;
                }
                if(this.scan){
                    this.$message.error("已有扫描任务进行中");
                    return;
                }
                this.scan = true;
                http.post("/client/task/scan", {clientId: this.client.id,path:this.query.path,types:this.query.types.join(",")}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.$message.success(`操作成功`);
                        api.getTaskLog(data.data,this.logs,this.$refs.logs,(data)=>{
                            this.scanData.push(data);
                        },()=>{
                            this.scan = false;
                        });
                        return;
                    }
                    this.$message.error(data.msg);
                    this.scan = false;
                }).catch(e => {
                    this.$message.error(e.message);
                    this.scan = false;
                })
            },
            //增加端口号
            addPort(key,port){
                if(!this.form[key]){
                    this.form[key] = port.toString();
                    return;
                }
                if(this.form[key].indexOf(port.toString())>=0){
                    return;
                }
                this.form[key] +=','+port;
            },
            //过滤可用端口
            filterPort(arr){
                let result = [];
                for (let i = 0; i < arr.length; i++) {
                    if(this.ports.indexOf(arr[i])<0){
                        result.push(arr[i]);
                    }
                    if(result.length>=5){
                        return result;
                    }
                }
                return result;
            },
            //检查端口格式
            checkPort(it){
                this.form[it] = this.form[it].replace(/[， ]/g,",").replace(/[^0-9,]/g,'');
            }
        },
        watch: {
            $route() {
                if (this.$route.name === "ClientServerScan") {
                    //初始化
                    this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .container{
        padding: 25px;
    }
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 120px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }
    .scan-log{
        width: 100%;
        border:1px solid #EEE;
        overflow: auto;
    }
    .scan-btn{
        position: absolute;
        right: 5px;
        top:6px;
    }
    .select-path{
        width: 100px;
    }
</style>
